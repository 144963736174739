<template>
    <!-- 基本信息 -->
        <div class="declare_form clearfix abcd">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm" :inline="true">
                <p class="form_title">
                    <span>
                        基本信息：
                    </span>
                </p>
                <div class="declare_form_one clearfix">
                    <el-col :span="8">
                        <el-form-item label="项目名称" prop="name" style="width:100%">
                            <el-input v-model="ruleForm.name" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="项目类型" prop="type">
                            <el-select v-model="ruleForm.type" placeholder="请选择项目类型">
                                <el-option label="创新训练项目" value="1"></el-option>
                                <el-option label="创业训练项目" value="2"></el-option>
                                <el-option label="创业实践项目" value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item prop="isYouth">
                            <el-checkbox v-model="ruleForm.isYouth">是否为青年红色筑梦之旅项目</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="项目所属院系" prop="academyId" style="width:100%">
                            <el-select v-model="ruleForm.academyId" placeholder="请选择院系">
                                <el-option 
                                    v-for="(item,index) in departList"
                                    :key="index"
                                    :label="item.yuanName" 
                                    :value="item.yuanId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="申报日期" prop="declarationTime" style="width:100%">
                            <el-date-picker
                                v-model="ruleForm.declarationTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="项目实施时间" prop="beginTime" style="width:60%">
                            <el-date-picker
                                v-model="tationof"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="getDate(tationof)">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </div>
                <p class="form_title">
                    <span>
                        项目综述：
                    </span>
                </p>
                <div class="declare_form_two clearfix">
                    <!-- 创新训练项目 -->
                    <div v-show="ruleForm.type!=2 && ruleForm.type!=3">
                        <el-col :span="20">
                            <el-form-item prop="s1" label="一、项目简介（200 字以内）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min: 0, max: 200, message: '内容在200字以内', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.s1" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s2" label="二、项目相关研究现状及发展动态（不少于 200 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:200, message: '内容不能少于200字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.s2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s3" label="三、项目实施的目的、意义（不少于 200 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:200, message: '内容不能少于200字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.s3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s4" label="四、项目研究内容和拟解决的关键问题（不少于 300 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:300, message: '内容不能少于300字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.s4"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s5" label="五、项目研究与实施的基础条件（不少于 300 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:300, message: '内容不能少于300字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.s5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s6" label="六、项目实施方案（不少于 300 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:300, message: '内容不能少于300字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.s6"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s7" label="七、项目创新点及特色（不少于 300 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:300, message: '内容不能少于300字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.s7"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s8" label="八、已有基础" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="包括与本项目有关的研究积累和已取得的成绩、学校可以提供的条 件、尚缺少的条件及解决方法" v-model="ruleForm.s8"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="s9" label="九、项目研究进度安排及各阶段预期成果" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==1?true:false, message: '内容不能为空', trigger: 'blur' },
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="本栏内容为中期检查及结题答辩重要参考" v-model="ruleForm.s9"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <!-- 创业训练项目 -->
                    <div v-show="ruleForm.type==2">
                        <el-col :span="20">
                            <el-form-item prop="r1" label="一、项目简介（200 字以内）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                                { max:200, message: '内容在200字以内', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.r1" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r2" label="二、项目实施的目的、意义" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.r2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r3" label="三、行业及市场前景（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="行业历史与前景，市场规模及增长趋势，行业竞争对手，未来市场销售预测等" v-model="ruleForm.r3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r4" label="四、创新点与项目特色（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="项目情况，技术水平，产品或模式的创新性、先进性和独特性，竞争优势" v-model="ruleForm.r4"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r5" label="五、生产或运营（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="生产或运营方式，材料、劳动力、设备需求，质量保证，生产成本" v-model="ruleForm.r5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r6" label="六、投融资方案（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.r6"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r7" label="七、管理模式（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="合作计划，实施方案，机构设置，人员管理，销售策略等" v-model="ruleForm.r7"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r8" label="八、风险预测及应对措施（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.r8"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="r9" label="九、效益预测及阶段性成果（不少于 300 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==2?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:300, message: '内容不能少于300字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="本栏内容为中期检查及结题答辩重要参考，明确填写立项期间取得标志性业绩或成果，并填写未来三年至五年的销售收入、利润、资产 回报率等" v-model="ruleForm.r9"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <!-- 创业实践项目 -->
                    <div v-show="ruleForm.type==3">
                        <el-col :span="20">
                            <el-form-item prop="t1" label="一、项目简介（200 字以内）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { max:200, message: '内容在200字以内', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t1" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t2" label="二、项目背景" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t3" label="三、行业及市场前景（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="行业历史与前景，市场规模及增长趋势，行业竞争对手，未来市场销售预测等" v-model="ruleForm.t3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t4" label="四、创业计划书" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t4"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t5" label="五、创新点与项目特色（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t5"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t6" label="六、技术或商业模式（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t6"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t7" label="七、创业过程与商业分析（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t7"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t8" label="八、管理模式（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t8"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t9" label="九、创业投融资计划（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t9"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t10" label="十、企业成长预测（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t10"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t11" label="十一、风险预测及应对措施（不少于 400 字）" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:400, message: '内容不能少于400字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="ruleForm.t11"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item prop="t12" label="十二、效益预测（不少于300 字)" style="width:100%"
                            :rules="[
                                { required: ruleForm.type==3?true:false, message: '内容不能为空', trigger: 'blur' },
                                { min:300, message: '内容不能少于300字', trigger: 'blur' }
                            ]">
                                <el-input type="textarea" :rows="6" placeholder="本栏内容为中期检查及结题答辩重要参考, 明确填写立项期间取得 的标志性成果和业绩，并填写未来三到五年的销售收入、利润、资产回报率等" v-model="ruleForm.t12"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                    <el-col :span="22">
                        <el-form-item prop="s10" :label="ruleForm.type==3?'十三、经费预算':'十、经费预算'" style="width:100%">
                            <div class="Addfunds">
                                <el-button size="mini" type="primary" @click="dialogVisible=true" v-show="this.ruleForm.s10.length<4">添加经费</el-button>
                                <el-button size="mini" type="info" v-show="this.ruleForm.s10.length>=4">添加经费</el-button>
                            </div>
                            <el-table 
                                :data="ruleForm.s10" 
                                style="width: 100%;"
                                stripe
                                show-summary
                                :summary-method="getSummaries"
                                :cell-style="{'text-align':'center'}"
                                :header-cell-style="{background:'#fafafa',color:'rgba(0,0,0,.85)','text-align':'center','line-height':'1'}"
                            >   
                                <!-- <el-table-column type="index" width="55" label="#"></el-table-column> -->
                                <el-table-column prop="j1" label="开支科目" width="300"></el-table-column>
                                <el-table-column prop="j2" label="预算经费（元）" width="120"></el-table-column>
                                <el-table-column prop="j3" label="主要用途"></el-table-column>
                                <el-table-column label="操作" width="100">
                                    <template slot-scope="scope">
                                        <el-button @click="deleteteacher(scope.$index,ruleForm.s10)" size="mini" type="danger">删除</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="上传附件" style="width:100%">
                            <el-upload
                                class="upload-demo"
                                :headers="headers"
                                :action="this.$store.state.uploadimage"
                                :on-success="handlePreview"
                                :on-remove="handleRemove"
                                multiple
                                :file-list="fileList">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </div>
            </el-form>
            <div class="next_step">
                <el-button type="primary" @click="newtion('ruleForm')">下一步</el-button>
            </div>
            <div class="adds_Popup">
            <el-dialog
                title="添加经费"
                :visible.sync="dialogVisible"
                width="500px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('Form')"></i>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="j1" label="开支项目" style="width:100%">
                        <el-input v-model="Form.j1"></el-input>
                    </el-form-item>
                    <el-form-item prop="j2" label="预算经费" style="width:100%">
                        <el-input v-model.number="Form.j2">
                            <template slot="append">元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="j3" label="主要用途" style="width:100%">
                        <el-input type="textarea" :rows="5" v-model="Form.j3"></el-input>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="addmbers('Form')">确 定</el-button>
                    <el-button size="small" @click="closei('Form')">取 消</el-button>
                </span>
            </el-dialog>
        </div>  
        </div>
</template>
<script>
export default {
    computed: {
        headers() {
            return{
                "Authorization": this.$store.state.token // 直接从本地获取token就行
            }
        }
    },
    data () {
        return {
            departList:[],
            tationof:'',
            dialogVisible:false,
            Form:{
                j1:'',
                j2:'',
                j3:'',
            },
            ruleForm:{
                isYouth:false,
                states:0,
                remark:'',
                s10:[],
                declarationTime:'',
                academyId:'',
                type:'',
                name:'',
                beginTime:'',
                endTime:'',
                s1:'',
                s2:'',
                s3:'',
                s4:'',
                s5:'',
                s6:'',
                s7:'',
                s8:'',
                s9:'',
                r1:'',
                r2:'',
                r3:'',
                r4:'',
                r5:'',
                r6:'',
                r7:'',
                r8:'',
                r9:'',
                t1:'',
                t2:'',
                t3:'',
                t4:'',
                t5:'',
                t6:'',
                t7:'',
                t8:'',
                t9:'',
                t10:'',
                t11:'',
                t12:'',
            },
            tableData:[],
            fileList:[],
            rules:{
                name:[
                    { required: true, message: '请输入项目名称', trigger: 'blur' }
                ],
                type:[
                    { required: true, message: '请选择项目类型', trigger: 'change' }
                ],
                academyId:[
                    { required: true, message: '请选择所属院系', trigger: 'change' }
                ],
                declarationTime:[
                    { required: true, message: '请选择申报日期', trigger: 'change' }
                ],
                beginTime:[
                    { required: true, message: '请选择项目实施时间', trigger: 'blur' }
                ],
                j1:[
                    { required: true, message: '请输入开支项目', trigger: 'blur' }
                ],
                j2:[
                    { required: true, message: '请输入预算经费', trigger: 'blur' },
                    { type: 'number', message: '经费必须为数字值'}
                ],
                j3:[
                    { required: true, message: '请输入主要用途', trigger: 'blur' }
                ],
                // 经费
                s10:[
                    { required: true, message: '至少添加一条经费预算', trigger: 'blur' }
                ],
            },

        }
    },
    methods: {
        getapp(){
            this.axios.studentuid({
                params:{
                    uid:this.$store.state.uid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.departList = res.data.data.yuanxi
                }
            }).catch(err=>{

            })
            if(this.$store.state.itemId!==null && this.$store.state.itemId!==''){
                this.axios.getInfo({
                    params:{
                        uid:this.$store.state.uid,
                    }
                }).then(res=>{
                    if(res.data.code==200){
                        var arr = []
                        arr.push(res.data.data.item.beginTime,res.data.data.item.endTime)
                        this.tationof = arr
                        this.ruleForm.academyId = res.data.data.item.academyId
                        this.ruleForm.beginTime = res.data.data.item.beginTime
                        this.ruleForm.createTime = res.data.data.item.createTime
                        this.ruleForm.declarationTime = res.data.data.item.declarationTime
                        this.ruleForm.endTime = res.data.data.item.endTime
                        this.ruleForm.fundSum = res.data.data.item.fundSum
                        this.ruleForm.itmId = res.data.data.item.itmId
                        this.ruleForm.name = res.data.data.item.name
                        this.ruleForm.onAcademyUid = res.data.data.item.onAcademyUid
                        this.ruleForm.onSchoolUid = res.data.data.item.onSchoolUid
                        this.ruleForm.itId = res.data.data.item.itId
                        this.ruleForm.createBy = res.data.data.item.createBy
                        this.ruleForm.academyName = res.data.data.item.academyName
                        this.ruleForm.onTeacherUid = res.data.data.item.onTeacherUid
                        this.ruleForm.opinion = res.data.data.item.opinion
                        this.ruleForm.opinionAcademy = res.data.data.item.opinionAcademy
                        this.ruleForm.opinionSchool = res.data.data.item.opinionSchool
                        this.ruleForm.opinionTeacher = res.data.data.item.opinionTeacher
                        this.ruleForm.params = res.data.data.item.params
                        this.ruleForm.priname = res.data.data.item.priname
                        this.ruleForm.projectNumber = res.data.data.item.projectNumber
                        this.ruleForm.rank = res.data.data.item.rank
                        this.ruleForm.schoolId = res.data.data.item.schoolId
                        this.ruleForm.score = res.data.data.item.score
                        this.ruleForm.searchValue = res.data.data.item.searchValue
                        this.ruleForm.states = res.data.data.item.states
                        this.ruleForm.sums = res.data.data.item.sums
                        this.ruleForm.tId = res.data.data.item.tId
                        this.ruleForm.tname = res.data.data.item.tname
                        this.ruleForm.type = res.data.data.item.type
                        this.ruleForm.uid = res.data.data.item.uid
                        this.ruleForm.updateBy = res.data.data.item.updateBy
                        this.ruleForm.updateTime = res.data.data.item.updateTime

                        this.ruleForm.isYouth = res.data.data.item.isYouth==1?false:true
                        if(res.data.data.item.type==1){
                            this.ruleForm.s1 = JSON.parse(res.data.data.item.remark).s1
                            this.ruleForm.s2 = JSON.parse(res.data.data.item.remark).s2
                            this.ruleForm.s3 = JSON.parse(res.data.data.item.remark).s3
                            this.ruleForm.s4 = JSON.parse(res.data.data.item.remark).s4
                            this.ruleForm.s5 = JSON.parse(res.data.data.item.remark).s5
                            this.ruleForm.s6 = JSON.parse(res.data.data.item.remark).s6
                            this.ruleForm.s7 = JSON.parse(res.data.data.item.remark).s7
                            this.ruleForm.s8 = JSON.parse(res.data.data.item.remark).s8
                            this.ruleForm.s9 = JSON.parse(res.data.data.item.remark).s9
                            this.ruleForm.s10 = JSON.parse(res.data.data.item.remark).s10
                        }else if(res.data.data.item.type==2){
                            this.ruleForm.r1 = JSON.parse(res.data.data.item.remark).r1
                            this.ruleForm.r2 = JSON.parse(res.data.data.item.remark).r2
                            this.ruleForm.r3 = JSON.parse(res.data.data.item.remark).r3
                            this.ruleForm.r4 = JSON.parse(res.data.data.item.remark).r4
                            this.ruleForm.r5 = JSON.parse(res.data.data.item.remark).r5
                            this.ruleForm.r6 = JSON.parse(res.data.data.item.remark).r6
                            this.ruleForm.r7 = JSON.parse(res.data.data.item.remark).r7
                            this.ruleForm.r8 = JSON.parse(res.data.data.item.remark).r8
                            this.ruleForm.r9 = JSON.parse(res.data.data.item.remark).r9
                            this.ruleForm.s10 = JSON.parse(res.data.data.item.remark).s10
                        }else{
                            this.ruleForm.t1 = JSON.parse(res.data.data.item.remark).t1
                            this.ruleForm.t2 = JSON.parse(res.data.data.item.remark).t2
                            this.ruleForm.t3 = JSON.parse(res.data.data.item.remark).t3
                            this.ruleForm.t4 = JSON.parse(res.data.data.item.remark).t4
                            this.ruleForm.t5 = JSON.parse(res.data.data.item.remark).t5
                            this.ruleForm.t6 = JSON.parse(res.data.data.item.remark).t6
                            this.ruleForm.t7 = JSON.parse(res.data.data.item.remark).t7
                            this.ruleForm.t8 = JSON.parse(res.data.data.item.remark).t8
                            this.ruleForm.t9 = JSON.parse(res.data.data.item.remark).t9
                            this.ruleForm.t10 = JSON.parse(res.data.data.item.remark).t10
                            this.ruleForm.t11 = JSON.parse(res.data.data.item.remark).t11
                            this.ruleForm.t12 = JSON.parse(res.data.data.item.remark).t12
                            this.ruleForm.s10 = JSON.parse(res.data.data.item.remark).s10
                        }
                        this.fileList = JSON.parse(res.data.data.item.attach)
                    }
                }).catch(err=>{

                })
            }else{
                return false
            }
        },
        //添加经费
        addmbers(Form){
            this.$refs[Form].validate((valid) => {
                if (valid) {
                        var arr = []
                        arr.push({j1:this.Form.j1,j2:this.Form.j2,j3:this.Form.j3,})
                        arr.map(item=>{
                            this.ruleForm.s10.push(item)
                        })
                        this.dialogVisible = false
                        this.$refs[Form].resetFields();
                }else{
                    return false
                }
            })
        },
        //删除行
        deleteteacher(index,rows){
            rows.splice(index, 1)
        },
        //选择项目日期
        getDate(val){
            if(val!=null){
                this.ruleForm.beginTime=val[0];
                this.ruleForm.endTime=val[1];
            }else{
                this.ruleForm.beginTime='';
                this.ruleForm.endTime='';
            }
        },
        //添加项目信息
        newtion(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 添加项目
                    if(this.$store.state.itemId===null || this.$store.state.itemId==='' ){
                        this.ruleForm.attach = JSON.stringify(this.fileList)
                        this.ruleForm.isYouth = this.ruleForm.isYouth== true ? 0 : 1
                        this.ruleForm.uid = this.$store.state.uid
                        var obj = {}
                        if(this.ruleForm.type==1){
                            obj.s1 = this.ruleForm.s1
                            obj.s2 = this.ruleForm.s2
                            obj.s3 = this.ruleForm.s3
                            obj.s4 = this.ruleForm.s4
                            obj.s5 = this.ruleForm.s5
                            obj.s6 = this.ruleForm.s6
                            obj.s7 = this.ruleForm.s7
                            obj.s8 = this.ruleForm.s8
                            obj.s9 = this.ruleForm.s9
                            obj.s10 = this.ruleForm.s10
                        }else if(this.ruleForm.type==2){
                            obj.r1 = this.ruleForm.r1
                            obj.r2 = this.ruleForm.r2
                            obj.r3 = this.ruleForm.r3
                            obj.r4 = this.ruleForm.r4
                            obj.r5 = this.ruleForm.r5
                            obj.r6 = this.ruleForm.r6
                            obj.r7 = this.ruleForm.r7
                            obj.r8 = this.ruleForm.r8
                            obj.r9 = this.ruleForm.r9
                            obj.s10 = this.ruleForm.s10
                        }else{
                            obj.t1 = this.ruleForm.t1
                            obj.t2 = this.ruleForm.t2
                            obj.t3 = this.ruleForm.t3
                            obj.t4 = this.ruleForm.t4
                            obj.t5 = this.ruleForm.t5
                            obj.t6 = this.ruleForm.t6
                            obj.t7 = this.ruleForm.t7
                            obj.t8 = this.ruleForm.t8
                            obj.t9 = this.ruleForm.t9
                            obj.t10 = this.ruleForm.t10
                            obj.t11 = this.ruleForm.t11
                            obj.t12 = this.ruleForm.t12
                            obj.s10 = this.ruleForm.s10
                        }
                        var str = JSON.stringify(obj)
                        this.ruleForm.remark = str
                        this.ruleForm.schoolId = this.$store.state.schId
                        this.axios.addStudent(
                            this.ruleForm
                        ).then(res=>{
                            if(res.data.code==200){
                                this.$store.dispatch('itemId', res.data.data.itemId)
                                this.$router.push('/Newmembers')
                            }else{
                                this.$message.error(res.data.msg)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        // 修改项目
                        this.ruleForm.attach = JSON.stringify(this.fileList)
                        this.ruleForm.isYouth = this.ruleForm.isYouth== true ? 0 : 1
                        this.ruleForm.uid = this.$store.state.uid
                        this.ruleForm.itmId = this.$store.state.itemId
                        this.ruleForm.schoolId = this.$store.state.schId
                        var obj = {}
                        if(this.ruleForm.type==1){
                            obj.s1 = this.ruleForm.s1
                            obj.s2 = this.ruleForm.s2
                            obj.s3 = this.ruleForm.s3
                            obj.s4 = this.ruleForm.s4
                            obj.s5 = this.ruleForm.s5
                            obj.s6 = this.ruleForm.s6
                            obj.s7 = this.ruleForm.s7
                            obj.s8 = this.ruleForm.s8
                            obj.s9 = this.ruleForm.s9
                            obj.s10 = this.ruleForm.s10
                        }else if(this.ruleForm.type==2){
                            obj.r1 = this.ruleForm.r1
                            obj.r2 = this.ruleForm.r2
                            obj.r3 = this.ruleForm.r3
                            obj.r4 = this.ruleForm.r4
                            obj.r5 = this.ruleForm.r5
                            obj.r6 = this.ruleForm.r6
                            obj.r7 = this.ruleForm.r7
                            obj.r8 = this.ruleForm.r8
                            obj.r9 = this.ruleForm.r9
                            obj.s10 = this.ruleForm.s10
                        }else{
                            obj.t1 = this.ruleForm.t1
                            obj.t2 = this.ruleForm.t2
                            obj.t3 = this.ruleForm.t3
                            obj.t4 = this.ruleForm.t4
                            obj.t5 = this.ruleForm.t5
                            obj.t6 = this.ruleForm.t6
                            obj.t7 = this.ruleForm.t7
                            obj.t8 = this.ruleForm.t8
                            obj.t9 = this.ruleForm.t9
                            obj.t10 = this.ruleForm.t10
                            obj.t11 = this.ruleForm.t11
                            obj.t12 = this.ruleForm.t12
                            obj.s10 = this.ruleForm.s10
                        }
                        var str = JSON.stringify(obj)
                        this.ruleForm.remark = str
                        this.axios.uptItemStudent(
                            this.ruleForm
                        ).then(res=>{
                            if(res.data.code==200){
                                this.$router.push('/Newmembers')
                            }else{
                                this.$message.error(res.data.msg)
                            }
                        }).catch(err=>{
                            
                        })
                    }
                }else{

                }
            })
        },
        // 附件上传成功
        handlePreview(res,file,flieList) {
            this.fileList = flieList
        },
        //删除附件
        handleRemove(file, flieList) {
            this.fileList = flieList
        },
        handleClose(done){

        },
        // 合计
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计'
                    //如果是最后一列，索引为列数-1，则显示计算总和
                } else if (index === 1) {
                    const values = data.map(item => Number(item[column.property]))
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                        }, 0)
                        sums[index] += ' 元';
                    } else {
                        sums[index] = ''
                    }
                //如果是除了第一列和最后一列的其他列，则显示为空
                } else {
                    sums[index] = ''
                }
            });

            return sums;
        },
        closei(formName){
            this.dialogVisible =false
            this.$refs[formName].resetFields();
        },
    },
    mounted () {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Projectapplication.css';
.Addfunds{
    position: absolute;
    right: 20px;
    top: -40px;
}
</style>
<style>
.declare_form .el-date-editor.el-input{
    width:100%;
}
.declare_form_one .el-select{
    width: 100%;
}
.declare_form_one .el-form-item__content{
    width: calc(100% - 120px);
}
.declare_form_two .el-form-item__content{
    width: 100%;
}
.declare_form_two .el-form-item__label{
    width: 100%!important;
    text-align: left!important;
}
.declare_form_two .el-textarea__inner{
    resize: none;
}
.declare_form_two .el-table td{
    text-align: center;
}
.abcd .declare_form_two .el-form-item{
    margin-bottom:22px
}
.adds_Popup .el-dialog__header{
    background: #409eff;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
}
.adds_Popup .el-dialog__title{
    color: #fff;
}
.adds_Popup .el-form-item__content{
    width: 300px;
}
.adds_Popup .el-dialog__footer{
    text-align: center;
}
.adds_Popup .el-textarea__inner{
    resize: none;
}
</style>